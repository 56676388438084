import React, { useContext } from 'react';

import { createNamedStyled } from '../../stitches.config';
import { useColorOverrides, useTheme } from '../../theme';

import Types from '../../modules/types';

import OrderContext from '../../context/Order';
import StoreContext from '../../context/Store';
import { useDictionary } from '../../context/Language';

import Logo from '../../components/Elements/Logo';
import { Title, Label, Paragraph } from '../../components/Elements/Text';
import Button from '../../components/Elements/Button';
import Footer from '../../components/Elements/Footer';

import PriceWithCurrency from '../../helpers/PriceWithCurrency';

import Items from '../../components/Elements/Cart/Items';
import PaymentRequestButton from './PaymentRequestButton';

import Review from './Review';
import PaymobPaymentRequestButton from './paymobPaymentRequestButton';

const styled = createNamedStyled('Order');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$s',

  width: '100%',
  maxWidth: '$siteWidth',
  margin: '0 auto',

  minHeight: '$screenHeight',
});

const Container = styled.named('Container')('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$m',
  flex: '1 0 auto',
  width: '100%',
  maxWidth: '800px',

  padding: '$s',
  '@desktop+': { padding: '$m' },
});

const Status = styled.named('Status')('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  gap: '$s',
  width: '100%',
});

const Group = styled.named('Group')('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const Line = styled.named('Line')('div', {
  width: '100%',
  height: '1px',
  backgroundColor: '$shade20',
});

const Info = styled.named('Row')('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$s',
  width: '100%',

  '@phablet-': {
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
  },
});

const Actions = styled.named('Actions')('div', {
  display: 'flex',
  gap: '$s',

  '& > *:not(:first-child)': { display: 'none' },
});

const UnlockOnPurchase = ({ data }) => (data ? (
  <Group css={{
    zoom: 0.9,
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    gap: 12,

    '@phablet-': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  }}
  >
    <Paragraph>{data.description}</Paragraph>
    <Button
      css={{ zoom: 0.75, whiteSpace: 'nowrap' }}
      to={data.actionLink}
    >
      {data.actionLabel}
    </Button>
  </Group>
) : null);

const Content = ({ isPosCheckout, isSelfPickup }) => {
  const { data: order } = useContext(OrderContext);
  const { data: store } = useContext(StoreContext);

  const { language: { language } } = useTheme();

  const colorOverrides = useColorOverrides('order', true);

  // const { data: store, getUrl: getStoreUrl } = useContext(StoreContext);

  // const { currency: systemCurrency } = Types.getSystemCountry(
  //   store.systemCountry,
  // );
  const {
    orderTitleSuccess,
    orderTitlePending,
    orderStatusTitle,
    orderStatusInvalid,
    orderStatusPaymentFailed,
    orderStatusDenied,
    orderStatusInvalidShipping,
    orderStatusDraft,
    orderStatusPendingPayment,
    orderStatusPendingApproval,
    orderStatusNew,
    orderStatusOnDelivery,
    orderStatusDelivered,
    orderStatusFailed,
    orderTrackingNumber,
    orderIdTitle,
    orderTotalTitle,
    orderContinueShopping,
    orderPickupAddressPrefix,
    orderPickupPhoneNumberPrefix,
    // currency: dictionaryCurrency,
  } = useDictionary();

  // const currency = dictionaryCurrency[systemCurrency];

  const ORDER_STATUS_LABELS = [
    orderStatusInvalid,
    orderStatusPaymentFailed,
    orderStatusDenied,
    orderStatusInvalidShipping,
    orderStatusDraft,
    orderStatusPendingPayment,
    orderStatusPendingApproval,
    orderStatusNew,
    orderStatusOnDelivery,
    orderStatusDelivered,
    orderStatusFailed,
  ];

const ORDER_STATUS_LABELS_MAP = Types.ORDER_STATUS.reduce(
    (agr, orderStatus, index) => {
      agr[orderStatus] = ORDER_STATUS_LABELS[index];
      return agr;
    },
    {},
  );

  const isPrecheckout = order.payment && order.payment.precheckout;

  if (!order || !store) {
    return null;
  }

  const items = order.items.map((item) => ({
    sku: item.sku,
    quantity: item.quantity,
    price: item.price,
    priceTotal: item.price * item.quantity,
    name: item.productName,
    variation: item.variationName,
    image: item.image.src,
    additionalInfo: <UnlockOnPurchase data={item.unlockOnPurchase} />,
  }));

  return (
    <Wrapper className={colorOverrides}>
      <Container>
        <Logo css={{ marginBottom: '$s', maxWidth: '260px !important' }} />
        <Title css={{ textAlign: 'center' }}>
          {order.status === 'INVALID_PAYMENT'
            ? ORDER_STATUS_LABELS_MAP[order.status]
            : order.status === 'PAYMENT'
              ? isPrecheckout
                ? language === 'ar'
                  // @TODO: Add to dictionary!!!
                  ? ':انقر للدفع'
                  : 'Click to Pay:'
                : orderTitlePending
              : orderTitleSuccess}
        </Title>
        {(
          order.status === 'INVALID_PAYMENT'
          ? (
            <Paragraph css={{ color: '$error' }}>
              {`${
                order.message
              }${
                order.payment.paytabsMessage
                ? `: ${order.payment.paytabsMessage}`
                : ''
              }`}
            </Paragraph>
          )
          : null
        )}
        {order.status === 'PAYMENT' && isPrecheckout ? (
          order.payment.provider === 'CHECKOUT' ?
          <PaymentRequestButton order={order} store={store} /> 
          : <PaymobPaymentRequestButton order={order} store={store} /> 
        ) : null }
        <Status>
          {/* ID */}
          <Group>
            <Paragraph bold>{order._id}</Paragraph>
            <Label>{orderIdTitle}</Label>
          </Group>
          {/* TRACKING */}
          {order.shipping.trackingId ? (
            <Group as="a" href={order.shipping.trackingUrl} target="_blank">
              <Paragraph bold>{order.shipping.trackingId}</Paragraph>
              <Label>{orderTrackingNumber}</Label>
            </Group>
          ) : null}
          {/* STATUS */}
          <Group>
            <Paragraph bold>
              {ORDER_STATUS_LABELS_MAP[order.status]}
            </Paragraph>
            <Label>
              {orderStatusTitle}
            </Label>
          </Group>
        </Status>
        {/* ITEMS */}
        <Items items={items} isInteractive={false} />
        {/* INFO */}
        <Line />
        <Info>
          <Group css={{ alignItems: 'flex-start' }}>
            <Paragraph bold css={{ fontSize: '120%' }}>
              {isSelfPickup
                ? order.pickupDetails.name
                : order.shippingDetails.name}
            </Paragraph>
            <Paragraph>
              {isSelfPickup
                ? order.pickupDetails.email
                : order.shippingDetails.email}
            </Paragraph>
            <br />
            {(!isSelfPickup && !isPosCheckout) ? (
              <Paragraph>
                {order.shippingDetails.address.lines}
              </Paragraph>
            ) : null}
            {isSelfPickup ? (
              <>
                <Paragraph
                  as="a"
                  target="_blank"
                  // eslint-disable-next-line max-len
                  href={`https://www.google.com/maps/search/?api=1&query=${order.pickupDetails.address.coordinates[1]},${order.pickupDetails.address.coordinates[0]}`}
                >
                  {orderPickupAddressPrefix
                    || 'You can find us here (click to open Google Maps):'}
                  {' '}
                  {order.pickupDetails.address.lines}
                </Paragraph>
                <Paragraph>
                  {orderPickupPhoneNumberPrefix
                    || 'Any questions about your pickup? Call us at:'}
                  {' '}
                  {order.pickupDetails.phoneNumberString}
                </Paragraph>
              </>
            ) : null}
          </Group>
          <Group
            css={{
              alignItems: 'flex-end',
              textAlign: 'right',
              alignSelf: 'flex-start',
              '@phablet-': {
                alignSelf: 'flex-end',
              },
            }}
          >
            <Label>{orderTotalTitle}</Label>
            <Label bold css={{ fontSize: '110%' }}>
              <PriceWithCurrency value={order.cost.charged} />
            </Label>
          </Group>
        </Info>
        <br />
        {!isPrecheckout && (
          <Actions>
            <Review />
            <Button to="/shop">{orderContinueShopping}</Button>
          </Actions>
        )}
      </Container>
      <Footer />
    </Wrapper>
  );
};

export default Content;
