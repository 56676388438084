import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  // useCallback,
} from 'react';
import { useForm } from 'react-hook-form';
import getKey from 'lodash/get';
import uuid from 'react-uuid';

import moment from 'moment-timezone';

import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

import { parsePhoneNumber as formatPhoneNumber } from 'phonumber';
import parsePhoneNumber, {
  // AsYouType,
  getCountryCallingCode,
} from 'libphonenumber-js';

import { createNamedStyled, keyframes } from '../../../stitches.config';
import { useTheme } from '../../../theme';

import {
  // usePriceWithCurrency,
  getPriceWithVat,
} from '../../../helpers/PriceWithCurrency';

import Types from '../../../modules/types';

import {
  useCart,
  useShippingCountryOptions,
  useShippingOptions,
  useShippingScheduledOptions,
  usePaymentOptions,
} from '../../../context/Cart';
import { useStore, useStoreCurrency } from '../../../context/Store';
import { useDictionary } from '../../../context/Language';

import { Title, Label, Paragraph, Link } from '../Text';
import Button from '../Button';

import { ControlledInput as Input } from '../Input';
import { Controlled as ButtonGroup } from '../ButtonGroup';

// import Map from '../Map';
import Checkout from './Checkout';
// import Stripe from './Stripe';
import Total from './Total';

const styled = createNamedStyled('CartForm');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 'calc($xs / 2)',
});

const PaymobWrapper = styled.named('Paymob')('div', {
  width: '100%',
  overflow: 'hidden',
  // marginBottom: '-$s',
  marginTop: '$s',
  fontFamily: '$text',

  height: '120px',

  '*:not(:has(iframe), iframe)': {
    display: 'none',
  },

  '[class^="error-message_container"]': {
    fontFamily: '$text',
    textAlign: 'center',
    color: '$error',
    margin: '0 auto $xs',
  },
});

const PaymobApplePayButton = styled.named('PaymobApplePayButton')('div', {
  transition: 'opacity 300ms',
});

const TabbyPromo = styled.named('TabbyPromo')(Paragraph, {
  '> div': {
    marginTop: '$xs',
    marginLeft: '-10px',
    marginRight: '-10px',
    overflowX: 'scroll',
    '> div': {
      background: 'transparent',
      backgroundColor: 'transparent',
      filter: 'invert(var(--custom-themeMode))',
      display: 'flex',
      width: '100%',
    },
    '> div > div': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      '& > div': {
        padding: '0px 5px',
        width: '100% !important',
        maxWidth: '100% !important',
        '&:before': {
          left: '50% !important',
        },
      },
    },
    '> div > p': {
      display: 'none !important',
    },
  },
});

const InputRowWrapper = styled.named('InputRowWrapper')('div', {
  display: 'flex',
  gap: '$s',
});

const SectionLabelWrapper = styled.named('SectionLabelWrapper')('div', {
  marginTop: '$m',
  marginBottom: '$xs',

  '&:first-of-type': { marginTop: '0' },
});

const SectionLabel = ({ label, description, ...props }) => (
  <SectionLabelWrapper {...props}>
    <Label>{label}</Label>
    {/* <Paragraph css={{ fontSize: '75%', opacity: 0.5 }}>
      {description}
    </Paragraph> */}
  </SectionLabelWrapper>
);

const TermsAndConditions = styled.named('TermsAndConditions')('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 'calc($xs / 2)',
  margin: '$s auto',
  textAlign: 'center',
  opacity: 0.75,

  '& > *': { fontSize: '75%' },
});

const TermsAndConditionsCheckbox = styled.named('TermsAndConditionsCheckbox')(
  'input',
  {
    accentColor: '$colors$brandColor',
    transform: 'translateY(-1px)',
    verticalAlign: 'middle',
    zoom: 1.2,
  },
);

const slideIn = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-20%)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const paymentLoading = keyframes({
  '0%': { opacity: 0.7 },
  '100%': { opacity: 0.4 },
});

const Animated = styled.named('Animated')('div', {
  display: 'flex',
  flexDirection: 'column',
  // paddingTop: '$m',
  gap: 'calc($xs / 2)',

  opacity: 0,
  transform: 'translateY(-20%)',
  animation:
    `${slideIn} $transitions$m $transitions$xs $transitions$ease forwards`,
});

const PATTERNS = {
  latin: /^[a-zA-Z\s]*$/,
  latinExtended: /^[a-zA-Z0-9\s]*$/,
  latinFull: /^[A-Za-z0-9_ !@#$%^&*().,:;"'_=+\-\r\n]+$/,
  numbers: /^[0-9]*$/,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  phoneNumberFormatted: /\+[\d\s\-()]*/,
};

const FILTERS = {
  email: /[^a-zA-Z0-9._@+%-]/g,
}

const phoneNumberFormats = {
  RU: { mask: '(###) ###-####' },
  US: { mask: '(###) ###-####' },
  RS: { mask: '(##) ###-####' },
  AE: { mask: '(##) ###-####' },
};

const getAddressComponent = (result, type) => {
  const value = result.address_components.find(component => (
    component.types.includes(type))
  );

  // console.log({ result, type, value: value.long_name });

  return value ? value.long_name : '';
};

// eslint-disable-next-line no-unused-vars
const Form = (props) => {
  const cart = useCart();
  const { data: store, language } = useStore();
  const [currencyObject, setCurrency] = useStoreCurrency();
  const shippingOptions = useShippingOptions();
  const shippingScheduledOptions = useShippingScheduledOptions();
  const shippingCountryOptions = useShippingCountryOptions();
  const paymentOptions = usePaymentOptions();

  const {
    language: themeLanguage,
    radii,
    colors,
  } = useTheme();

  const {
    cartFirstName,
    cartLastName,
    cartEmail,
    cartPhone,
    cartCountry,
    cartAddress,
    cartAddressAdditional,
    cartAddressCity,
    cartAddressArea,
    cartAddressStreetName,
    cartAddressStreetNumber,
    cartDiscountCode,
    cartOrderComment,
    cartCheckoutTitle,
    cartShippingInformation,
    cartOrderDetails,
    cartCardDetails,
    cartPreferedShipping,
    cartNoShippinMethodsAvailable,
    cartScheduledShippingDateTitle,
    cartScheduledShippingDateLabel,
    cartPaymentMethod,
    cartNoPaymentMethodsAvailable,
    cartSubmit,
    cartSubmitting,
    cartAgreement,
    cartAgreementStrict,
    cartTermsAndConditions,
    cartPostalCode,
    cartInternationalDisclaimer,
    cartContinueShopping,
    paymobCardFormValidationEmptyError,
    paymobCardFormValidationInvalidError,
    cartSessionExpiredError
  } = useDictionary();

  const {
    // fieldNamesList,
    fieldList,
  } = useMemo(
    () => {
      const keys = [
        'firstName',
        'lastName',
        'email',
        'phoneNumberString',
        'country',
        'line1',
        'line2',
        'coordinatesString',
        'city',
        'postalCode',
        'district',
        'area',
        'neighbourhood',
        'streetName',
        'streetNumber',
        'shipping',
        'shippingScheduled',
        'shippingScheduledFor',
        'payment',
        'orderComment',
        'discountCode',
      ];
      return {
        fieldNamesList: keys,
        fieldList: keys.map(key => ({
          key,
          name: uuid(),
        })),
      };
    },
    [],
  );

  const {
    fieldKeys,
    fieldNames,
    fieldKeyNameMap,
    fieldNameKeyMap,
  } = useMemo(
    () => ({
      ...fieldList.reduce(
        (agr, { key, name }) => {
          agr.fieldKeys.push(key);
          agr.fieldNames.push(name);
          agr.fieldKeyNameMap[key] = name;
          agr.fieldNameKeyMap[name] = key;
          return agr;
        },
        {
          fieldKeys: [],
          fieldNames: [],
          fieldKeyNameMap: {},
          fieldNameKeyMap: {},
        },
      ),
    }),
    [fieldList],
  );

  const defaultValues = useMemo(
    () => fieldList.reduce(
      (agr, { key, name }) => {
        let defaultValue = cart.defaultSubmitFields[key];
        if (typeof defaultValue === 'undefined') {
          defaultValue = '';
        }
        agr[name] = defaultValue;
        return agr;
      },
      {},
    ),
    [cart.defaultSubmitFields, fieldList],
  );

  const [isStrictTocAgreed, setIsStrictTocAgreed] = useState(
    store.strictTermsAndConditions === false ? true : false
  );

  const [country, setCountry] = useState(
    cart.defaultSubmitFields.country || '',
  );

  let {
    paymentCardProvider,
  } = Types.getSystemCountry(store.systemCountry);

  if (store.paymentCardProvider) {
    paymentCardProvider = store.paymentCardProvider;
  }

  const form = useForm({
    defaultValues,
  });
  
  const {
    control,
    handleSubmit,
    setValue: setInputValue,
    formState: { errors },
  } = form;

  const didSetPhoneNumberStringCallingCode = useRef(false);
  const [
    changedCountry = cart.defaultSubmitFields.country,
    changePhoneNumberString,
  ] = form.watch([
    fieldKeyNameMap.country,
    fieldKeyNameMap.phoneNumberString,
  ]);

  useEffect(
    () => {
      if (
        country !== changedCountry
        || !didSetPhoneNumberStringCallingCode.current
      ) {
        didSetPhoneNumberStringCallingCode.current = true;
        if (!(changePhoneNumberString?.length > 5)) {
          setInputValue(
            fieldKeyNameMap.phoneNumberString,
            // TODO: error if country is not set
            `+${getCountryCallingCode(changedCountry?.toUpperCase() || 'AE')}`
          );
        }
        sessionStorage.removeItem('upa');
      }
      if (country !== changedCountry) {
        setCountry(changedCountry);
      }
    },
    [
      country,
      changedCountry,
      fieldKeyNameMap,
      changePhoneNumberString,
      setInputValue,
    ],
  );

  const {
    onFormInitialize: cartOnFormInitialize,
    onFormChange: cartOnFormChange,
  } = cart;

  useEffect(
    () => {
      const newForm = {
        ...form,
        // eslint-disable-next-line no-unused-vars
        setValue: (name, value = '', options) => {
          // name = fieldKeyNameMap[name];
          name = fieldKeyNameMap[name];
          return form.setValue(name, value);
        },
        getValues: () => {
          const values = form.getValues();
          const returnValue = {
            ...fieldList.reduce(
              (agr, { key, name }) => {
                agr[key] = values[name];
                return agr;
              },
              {},
            ),
          };
          return returnValue;
        },
      };
      cartOnFormInitialize(newForm);
    },
    [form, fieldList, fieldKeyNameMap, cartOnFormInitialize],
  );

  const {
    ready,
    // eslint-disable-next-line no-unused-vars
    value: addressSearch,
    // eslint-disable-next-line no-unused-vars
    suggestions: { status, data: googleAddressAutocompleteData },
    setValue: setAddressSearch,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      language: 'en',
      componentRestrictions: {
        country: country?.toUpperCase(),
      },
    },
    debounce: 300,
  });

  const formValuesArray = form.watch(fieldNames);
  const formValues = useMemo(
    () => fieldList.reduce(
      (agr, { key }, i) => {
        agr[key] = formValuesArray[i];
        return agr;
      },
      {},
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...formValuesArray, fieldList],
  );

  const onChangeValuesRef = useRef(formValuesArray.reduce(
    (agr, value, i) => {
      agr[fieldNameKeyMap[fieldNames[i]]] = value || '';
      return agr;
    },
    {},
  ));
  const onChangeCounterRef = useRef(0);

  useEffect(
    () => {
      if (onChangeCounterRef.current > 0) {
        let anyValueNotUndefined = false;
        const newValues = fieldKeys.reduce(
          (agr, key) => {
            const value = formValues[key];
            if (!anyValueNotUndefined) {
              anyValueNotUndefined = typeof value !== 'undefined';
              // if (anyValueNotUndefined) {
              //   console.log('NOT_UNDEFINED:', key, value);
              // }
            }
            agr[key] = value;
            return agr;
          },
          {},
        );
        if (anyValueNotUndefined) {
          cartOnFormChange(
            newValues,
            Object.keys(newValues).reduce(
              (agr, key) => {
                if (onChangeValuesRef.current[key] !== newValues[key]) {
                  agr.push(key);
                }
                return agr;
              },
              [/* */],
            ),
          );
          onChangeValuesRef.current = newValues;
        }
      }
      onChangeCounterRef.current++;
    },
    [formValues, fieldNames, cartOnFormChange, fieldKeys],
  );

  const {
    checkoutComChannel,
    getCheckoutComVersionBasedOnStoreId,
  } = Types.getSystemCountry(store.systemCountry);

  if (formValues.payment === 'CARD' && paymentCardProvider === 'CHECKOUT') {
    // eslint-disable-next-line no-console
    console.log(
      'Checkout:',
      getCheckoutComVersionBasedOnStoreId(store._id),
      '/',
      process.env[`REACT_APP_CHECKOUT_${
        getCheckoutComVersionBasedOnStoreId(store._id).toUpperCase()
      }_PUBLIC_KEY_${
        checkoutComChannel.toUpperCase()
      }`]
    );
  }

  const [isCartSubmitting, setIsCartSubmitting] = useState(false);
  const cartSubmissionTimeout = useRef(null);
  useEffect(() => {
    if (cart.submitting) {
      setIsCartSubmitting(true);
    } else {
      cartSubmissionTimeout.current = setTimeout(() => {
        setIsCartSubmitting(false);
        // ammar: used here to be paired with the cart.submitting state
        setIsSubmittingLocal(false); 
      }, 1000);
    }
    return () => {
      clearTimeout(cartSubmissionTimeout.current);
    };
  }, [cart.submitting]);

  const tabbyCurrencyObject = (
    // TODO Tabby merchantCode (new only)
    store.storeCurrenciesMap?.[Types.COUNTRIES_MAP[
      cart.defaultSubmitFields?.country
    ]?.currency] || store.storeCurrencies[0]
  );
  const { code: tabbyCurrency } = (
    tabbyCurrencyObject || {}
  );
  const tabbyPriceCharged = getPriceWithVat(
    cart.priceCharged,
    store,
    tabbyCurrencyObject,
    false,
  );

  useEffect(
    () => {
      let tabbyCardWrapper = null;
      if (
        store.paymentTabbySupported
        && formValues.payment === 'TABBY.PAY_BY_INSTALMENTS'
      ) {
        tabbyCardWrapper = document.createElement('div');
        tabbyCardWrapper.setAttribute('id', 'tabby-promo-cart-inner');
        document.querySelector('#tabby-promo-cart').append(tabbyCardWrapper);
        // eslint-disable-next-line no-new, no-undef
        new TabbyCard({
          installmentsCount: 4,
          lang: language._id === 'ar' ? 'ar' : 'en',
          selector: '#tabby-promo-cart-inner',
          // TODO Tabby merchantCode (new only)
          publicKey: (
            process.env.REACT_APP_TABBY_PUBLIC_KEY_THE_EXAMPLE_SHOP
          ),
          price: `${tabbyPriceCharged}`,
          currency: tabbyCurrency,
          source: 'cart',
          header: false,
          size: 'wide',
          theme: 'default',
        });
      }
      return () => {
        if (tabbyCardWrapper) {
          tabbyCardWrapper.remove();
        }
      };
    },
    [
      formValues.payment,
      store.paymentTabbySupported,
      // TODO Tabby merchantCode
      store.storeId,
      tabbyPriceCharged,
      tabbyCurrency,
      language?._id,
    ],
  );

  useEffect(
    () => {
      if (
        store.paymentTabbySupported
        && formValues.payment === 'TABBY.PAY_BY_INSTALMENTS'
        && cart?.cost?.chargedExchangeCurrency
        && currencyObject
        && currencyObject?.code !== cart?.cost?.chargedExchangeCurrency
      ) {
        setCurrency(cart?.cost?.chargedExchangeCurrency);
      }
    },
    [
      store.paymentTabbySupported,
      formValues.payment,
      cart?.cost?.chargedExchangeCurrency,
      currencyObject,
      setCurrency,
    ],
  );

  const [paymentMethodInUse, paymentSubmethodInUse] = useMemo(
    () => (formValues?.payment || '')?.split?.('.'),
    [formValues.payment]
  );

  const [isSubmittingLocal, setIsSubmittingLocal] = useState(false);
  const [formStateError, setFormStateError] = useState(null);

  useEffect(() => {
    let timeout = null;
    const callback = () => {
      if (!window.Pixel) {
        // eslint-disable-next-line no-console
        console.log('No Paymob Pixel, delaying:', window.Pixel);
        timeout = setTimeout(
          callback,
          500,
        );
        return;
      }
      if (
        paymentMethodInUse === 'CARD'
        && paymentCardProvider === 'PAYMOB_PIXEL'
        && cart.paymob.paymobClientSecret
      ) {
        const options = {
          publicKey: cart.paymob.paymobPublicKey,
          clientSecret: cart.paymob.paymobClientSecret,
          paymentMethods: ['card'],
          disablePay: true,
          showPaymobLogo: false,
          elementId: 'paymob-card-element',
          hideCardHolderName: true,
          customStyle: {
            Font_Weight_Label: store?.styleFontLabel?.variantNormal,
            Font_Weight_Input_Fields: store?.styleFontLabel?.variantNormal,
            Color_Border_Input_Fields: store?.brandColor,
            Color_Input_Fields: colors?.backgroundCard,
            Text_Color_For_Input_Fields: colors?.text,
            Color_For_Text_Placeholder: colors?.text,
            Radius_Border: radii.s.replace('px', ''),
          },
          cardValidationChanged : (isValid) => {
            console.log( "Is Valid ?", isValid);
            setFormStateError(isValid);
            isValid === true && setFormStateError(true)
          }
        };
        if (paymentSubmethodInUse === 'APPLE_PAY') {
          options.paymentMethods = ['apple-pay'];
          options.elementId = 'paymob-apple-pay-element';
          // options.afterPaymentComplete = async () => {
          //   setIsSubmittingLocal(false);
          //   console.log("testing payment: afterPaymentComplete")
          // };
          options.onPaymentCancel = async () => {
            console.log("testing payment: onPaymentCancel")
            try {
              await cart.cancelPreviousOrder();
            } catch (error) {
              // console.log()
            }
        
            cart.reEvaluate();
            // ammar: disbled here & used in top 
            // setIsSubmittingLocal(false);
          };
          options.beforePaymentComplete = async () => {
            setIsSubmittingLocal(true);
            const result = await new Promise((resolve) => {
              handleSubmit( 
                async (values) => {
                  const extraProps = {};
                  const submitValues = (
                    fieldList.reduce((agr, { key, name }) => {
                      agr[key] = values[name];
                      return agr;
                    }, {})
                  );
                  await cart.submit(submitValues, extraProps);
                  if (cart.error) {
                    // eslint-disable-next-line no-console
                    console.log('🚨 Error:', cart.error);
                    resolve(false);
                  }
                  resolve(true);
                },
                (submitErrors) => {
                  const [name] = Object.keys(submitErrors);
                  if (name) {
                    const selector = `[name="${name}"]`;
                    setIsSubmittingLocal(false);
                    setIsCartSubmitting(false);
                    const node = document.querySelector(selector);
                    if (node?.scrollIntoView) {
                      node.scrollIntoView({
                        block: 'center',
                        inline: 'nearest',
                      });
                    }
                  }
                  resolve(false);
                }
              )();
            });
            return result;
          };
        }
        // console.log(options);
        // eslint-disable-next-line no-new, no-undef
        // new Paymob(options);
        new Pixel(options);

      }
    };
    callback();
    return () => {
      clearTimeout(timeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // isInputInFocus,
    cart.paymob.paymobClientSecret,
    // cart.submit,

    // cart,
    // fieldList,
    handleSubmit,

    paymentCardProvider,
    paymentMethodInUse,
    paymentSubmethodInUse,
    formValues.payment,

    // colors.text,
    // colors.borderLight,
    // radii.s,
  ]);


  return (
    <Wrapper>
      <Title bold size="s">{cartCheckoutTitle}</Title>
      <SectionLabel
        label={cartShippingInformation}
        // description="Please make sure you have entered the correct information!"
      />
      <Input
        name={fieldKeyNameMap.firstName}
        error={errors[fieldKeyNameMap.firstName]}
        control={control}
        rules={{ required: true }}
        label={cartFirstName}
      />
      <Input
        name={fieldKeyNameMap.lastName}
        error={errors[fieldKeyNameMap.lastName]}
        control={control}
        rules={{ required: true }}
        label={cartLastName}
      />
      <Input
        name={fieldKeyNameMap.email}
        error={errors[fieldKeyNameMap.email]}
        control={control}
        rules={{ required: true, pattern: PATTERNS.email }}
        filter={FILTERS.email}
        label={cartEmail}
      />
      <Input
        name={fieldKeyNameMap.country}
        error={errors[fieldKeyNameMap.country]}
        control={control}
        rules={{ required: true }}
        label={cartCountry}
        options={shippingCountryOptions}
      />
      <Input
        name={fieldKeyNameMap.phoneNumberString}
        error={errors[fieldKeyNameMap.phoneNumberString]}
        control={control}
        rules={{
          required: true,
          validate: (value) => !!parsePhoneNumber(value)?.isValid(),
        }}
        prefix="+"
        format={(value) => (
          // using Phonumber npm package
          formatPhoneNumber(
            value, { formats: phoneNumberFormats }
          ).formattedNumber
          // using Androids's libphonenumber
          // new AsYouType().input(value)
        )}
        label={cartPhone}
        css={{
          direction: 'ltr',
          textAlign: themeLanguage.direction === 'rtl' ? 'right' : 'left',
        }}
      />
      <Input
        name={fieldKeyNameMap.line1}
        error={errors[fieldKeyNameMap.line1]}
        control={control}
        rules={{ required: true }}
        disabled={!ready}
        label={cartAddress}
        onSearch={(value) => {
          setAddressSearch(value);
        }}
        onChange={async (value) => {
          clearSuggestions();
          form.setValue(fieldKeyNameMap.line1, value);
          try {
            const [result] = await getGeocode({ address: value });
            if (result) {
              // console.log('📍 Address data: ', result);
              if (result.geometry?.location) {
                form.setValue(
                  fieldKeyNameMap.coordinatesString, [
                    result.geometry?.location.lng().toFixed(5),
                    result.geometry?.location.lat().toFixed(5),
                  ].join(',')
                );
              }

              if (result.address_components) {
                form.setValue(
                  fieldKeyNameMap.district, [
                    getAddressComponent(result, 'administrative_area_level_1'),
                    getAddressComponent(result, 'administrative_area_level_2'),
                  ].join(' ').trim()
                );
                form.setValue(
                  fieldKeyNameMap.city,
                  getAddressComponent(result, 'locality')
                );
                form.setValue(
                  fieldKeyNameMap.area,
                  getAddressComponent(result, 'sublocality')
                );
                form.setValue(
                  fieldKeyNameMap.neighbourhood,
                  getAddressComponent(result, 'neighborhood')
                );
                form.setValue(
                  fieldKeyNameMap.postalCode,
                  getAddressComponent(result, 'postal_code')
                );
                form.setValue(
                  fieldKeyNameMap.streetName,
                  getAddressComponent(result, 'route')
                );
                form.setValue(
                  fieldKeyNameMap.streetNumber,
                  getAddressComponent(result, 'street_number')
                );
              }
            }
          } catch (error) {
            // noop
          }
        }}
        options={googleAddressAutocompleteData?.map(({
          description,
          // eslint-disable-next-line no-unused-vars
          ...rest
        }) => (
          { value: description, label: description }
        )) || []}
        disableFiltering
      />
      {
        !formValues.line1
        ? null
        : (
            <>
              <InputRowWrapper>
                <Input
                  name={fieldKeyNameMap.city}
                  error={errors[fieldKeyNameMap.city]}
                  control={control}
                  rules={{ required: true }}
                  label={cartAddressCity}
                />
                <Input
                  name={fieldKeyNameMap.area}
                  error={errors[fieldKeyNameMap.area]}
                  control={control}
                  rules={{ required: true }}
                  label={cartAddressArea}
                />
              </InputRowWrapper>
              <InputRowWrapper>
                <Input
                  name={fieldKeyNameMap.streetName}
                  error={errors[fieldKeyNameMap.streetName]}
                  control={control}
                  rules={{ required: true }}
                  label={cartAddressStreetName}
                />
                <Input
                  name={fieldKeyNameMap.streetNumber}
                  error={errors[fieldKeyNameMap.streetNumber]}
                  control={control}
                  rules={{ required: true }}
                  label={cartAddressStreetNumber}
                />
              </InputRowWrapper>
            </>
          )
      }
      <Input
        name={fieldKeyNameMap.line2}
        error={errors[fieldKeyNameMap.line2]}
        control={control}
        label={cartAddressAdditional}
      />
      {
        formValues.country !== 'ae'
        ? (
            <Animated>
              <Input
                name={fieldKeyNameMap.postalCode}
                error={errors[fieldKeyNameMap.postalCode]}
                control={control}
                label={cartPostalCode}
                // rules={{ required: true }}
                // shouldUnregister
              />
            </Animated>
          )
        : null
      }
      {
        // (
        //   shippingOptions?.length > 0
        //   && !(
        //     ['POS', 'SELF_PICKUP', 'ABSTRACT']
        //     .includes(shippingOptions?.[0]?.value)
        //     && shippingOptions?.length === 1
        //   )
        // )
        !(
          ['POS', 'SELF_PICKUP', 'ABSTRACT']
          .includes(shippingOptions?.[0]?.value)
          && shippingOptions?.length === 1
        )
        ? (
            <Animated>
              <br />
              <SectionLabel
                label={cartPreferedShipping}
                // description="How would you like it shipped?"
              />
              <ButtonGroup
                name={fieldKeyNameMap.shipping}
                control={control}
                options={shippingOptions}
                noContentPlaceholder={cartNoShippinMethodsAvailable}
              />
              {
                cart.scheduling
                ? (
                    <>
                      <ButtonGroup
                        condensed
                        name={fieldKeyNameMap.shippingScheduled}
                        control={control}
                        options={shippingScheduledOptions}
                        onChange={(newValue) => {
                          if (newValue) {
                            form.setValue(
                              fieldKeyNameMap.shippingScheduledFor,
                              // moment(
                              //   new Date(Date.now() + 24 * 60 * 60 * 1000),
                              // ).format('YYYY-MM-DD'),
                              '',
                            );
                          } else {
                            form.setValue(
                              fieldKeyNameMap.shippingScheduledFor,
                              '',
                            );
                          }
                        }}
                      />
                      {
                        formValues.shippingScheduled
                        ? (
                            <>
                              <br />
                              <SectionLabel
                                label={cartScheduledShippingDateTitle}
                                // description="How would you like it shipped?"
                              />
                              <Input
                                name={fieldKeyNameMap.shippingScheduledFor}
                                error={
                                  errors[fieldKeyNameMap.shippingScheduledFor]
                                }
                                control={control}
                                min={
                                  moment(new Date(
                                    Date.now() + 24 * 60 * 60 * 1000
                                  ))
                                  .format('YYYY-MM-DD')
                                }
                                rules={{
                                  validate: (value) => {
                                    const date = new Date(value);
                                    if (!Number.isFinite(date.getTime())) {
                                      return false;
                                    }
                                    const momentDate = moment(date)
                                    .startOf('day');
                                    const momentNow = moment()
                                    .startOf('day');
                                    return (
                                      momentDate.toDate().getTime()
                                      > momentNow.toDate().getTime()
                                    );
                                  },
                                }}
                                label={cartScheduledShippingDateLabel}
                                placeholder={cartScheduledShippingDateLabel}
                                type="date"
                              />
                            </>
                          )
                        : null
                      }
                    </>
                  )
                : null
              }
            </Animated>
          )
        : null
      }
      <SectionLabel
        label={cartPaymentMethod}
        // description="How would you like to pay for it?"
      />
      <ButtonGroup
        name={fieldKeyNameMap.payment}
        control={control}
        options={paymentOptions}
        noContentPlaceholder={cartNoPaymentMethodsAvailable}
      />
      {
        formValues.payment === 'CARD'
        ? (
          paymentCardProvider === 'PAYMOB_PIXEL'
          && paymentSubmethodInUse !== 'APPLE_PAY'
          ? (
            <PaymobWrapper id="paymob-card-element" />
            // null
          )
          : paymentCardProvider === 'STRIPE'
          // ? (
          //   cart.stripePaymentIntent?.paymentIdClient
          //   ? <Stripe />
          //   : <div style={{ height: '200px' }} />
          // )
          ? null
          : paymentCardProvider === 'CHECKOUT'
          ? (
            <Animated>
              <br />
              <SectionLabel
                label={cartCardDetails || 'Card Details'}
                // description="Please enter your card information"
              />
              <Checkout
                ref={cart.checkoutCardInputRef}
                publicKey={
                  process.env[`REACT_APP_CHECKOUT_${
                    getCheckoutComVersionBasedOnStoreId(store._id).toUpperCase()
                  }_PUBLIC_KEY_${
                    checkoutComChannel.toUpperCase()
                  }`]
                }
              />
            </Animated>
          )
          : null
        )
        : null
      }
      {
        formValues.payment === 'TABBY.PAY_BY_INSTALMENTS'
        ? <TabbyPromo id="tabby-promo-cart" />
        : null
      }
      <SectionLabel
        label={cartOrderDetails}
        // description="If you want to leave us a comment, please do so here."
      />
      <Input
        name={fieldKeyNameMap.orderComment}
        error={errors[fieldKeyNameMap.orderComment]}
        control={control}
        label={cartOrderComment}
      />
      <Input
        name={fieldKeyNameMap.discountCode}
        rules={{
          // eslint-disable-next-line arrow-body-style
          validate: (value) => {
            return value && cart.discountCodeError
            ? 'Invalid Discount Code'
            : true
          },
        }}
        error={errors[fieldKeyNameMap.discountCode]}
        control={control}
        label={cartDiscountCode}
        // debounce={500}
      />
      <Total formValues={formValues} />
      <TermsAndConditions css={{ margin: '$s auto $s' }}>
          {
            cart.international && cartInternationalDisclaimer
            ? (
                <Paragraph>
                  {cartInternationalDisclaimer}
                </Paragraph>
              )
            : null
          }
      </TermsAndConditions>

      <TermsAndConditions css={{ marginTop: 0 }}>
        {
          store.strictTermsAndConditions
          ? (
              <>
                <Paragraph>
                  <TermsAndConditionsCheckbox
                    type="checkbox"
                    value={isStrictTocAgreed}
                    onChange={({ target }) => setIsStrictTocAgreed(
                      !!target.checked
                    )}
                  />
                  &nbsp;
                  {cartAgreementStrict}
                  &nbsp;
                  <a
                    href={getKey(
                      store,
                      'policies.tnc.src',
                      'https://packman.app/terms-and-conditions',
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {cartTermsAndConditions}
                  </a>
                </Paragraph>
              </>
            )
          : (
              <>
                <Paragraph>
                  {cartAgreement}
                </Paragraph>
                <Link
                  as="a"
                  href={getKey(
                    store,
                    'policies.tnc.src',
                    'https://packman.app/terms-and-conditions',
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {cartTermsAndConditions}
                </Link>
              </>
            )
        }
      </TermsAndConditions>

      {cart.error ? (
        <Paragraph
          css={{
            color: '$error',
            textAlign: 'center',
            margin: '$s auto $xs',
          }}
        >
          {cart.error === 'Session expired, please refresh the page.' ? cartSessionExpiredError : cart.error}
        </Paragraph> 
      ) : null}
      {
        // paymentCardProvider === 'PAYMOB_PIXEL'
        // && paymentSubmethodInUse === 'APPLE_PAY'
        // ? (
        //  <PaymobApplePayButton
        //     css={{
        //       ...(isSubmittingLocal || isCartSubmitting)
        //       && {
        //         opacity: 0.7,
        //         animation: `${paymentLoading} 1000ms 300ms infinite alternate`,
        //         pointerEvents: 'none',
        //       },
        //     }}
        //     id="paymob-apple-pay-element"
        //   />
        //   // null
        // )
        // : 
        (
         <>
         {
          (paymentCardProvider === 'PAYMOB_PIXEL' && paymentMethodInUse === 'CARD' && formStateError === null  && (!paymentSubmethodInUse || paymentSubmethodInUse !== 'APPLE_PAY'))&& ( <Paragraph    css={{
            color: '$error',
            textAlign: 'center',
            margin: '$s auto $xs',
          }}>{paymobCardFormValidationEmptyError}</Paragraph> )
         }
         {
            (paymentCardProvider === 'PAYMOB_PIXEL' && paymentMethodInUse === 'CARD'  &&  formStateError === false && (!paymentSubmethodInUse || paymentSubmethodInUse !== 'APPLE_PAY')) && ( <Paragraph    css={{
              color: '$error',
              textAlign: 'center',
              margin: '$s auto $xs',
            }}>{paymobCardFormValidationInvalidError}</Paragraph> )
         }
          <Button
            // type="submit"
            css={{
              width: '100%',
              maxWidth: 300,
              margin: '0 auto',
              transition: 'opacity 300ms',
              ...isCartSubmitting
              && {
                opacity: 0.7,
                animation: `${paymentLoading} 1000ms 300ms infinite alternate`,
                pointerEvents: 'none',
              },
            }}
            disabled={!isStrictTocAgreed || cart.evaluating || (paymentCardProvider === 'PAYMOB_PIXEL' &&  formStateError !== true && paymentMethodInUse === 'CARD' && (!paymentSubmethodInUse || paymentSubmethodInUse !== 'APPLE_PAY'))}
            onClick={   handleSubmit(
              async (values, event) => {
              
                event.preventDefault();
                const extraProps = {};

                const submitValues = fieldList.reduce(
                  (agr, { key, name }) => {
                    agr[key] = values[name];
                    return agr;
                  },
                  {},
                );
               
                if (
                  formValues.payment === 'CARD'
                  && paymentCardProvider === 'PAYMOB'
                ) {
                  const nameElement = document.querySelector(
                    '#paymob-card-element-inner input[name="name"]'
                  );

                  if (nameElement) {
                    nameElement.setAttribute('value', `${
                      submitValues.firstName.toUpperCase()
                    } ${
                      submitValues.lastName.toUpperCase()
                    }`);

                    nameElement.dispatchEvent(new Event(
                      'change',
                      { bubbles: true }
                    ));
                  }
                }
                if(paymentCardProvider === 'PAYMOB_PIXEL' && paymentMethodInUse === 'CARD' ) {
                  if(paymentSubmethodInUse === 'APPLE_PAY') {
                    cart.submit(submitValues, extraProps)
                  } else {
                    (formStateError === true ) && cart.submit(submitValues, extraProps);
                  }
                } else {
                  cart.submit(submitValues, extraProps)
                } 
              },
              (submitErrors, event) => {
                event.preventDefault();
                const [name] = Object.keys(submitErrors);
                if (name) {
                  const selector = `[name="${name}"]`;
                  const node = document.querySelector(selector);
                  if (node?.scrollIntoView) {
                    // console.log('node:', node);
                    node.scrollIntoView({
                      // behavior: 'smooth',
                      block: 'center',
                      inline: 'nearest',
                    });
                  }
                }
              },
            )
            
          }
          >
            {isCartSubmitting ? cartSubmitting : cartSubmit}
          </Button>
         </>
        )
      }

      <TermsAndConditions>
        <Paragraph css={{ textAlign: 'center', margin: '$s auto $xs' }}>
          <Link
            as="a"
            onClick={() => cart.toggleShowCart(false)}
            target="_blank"
            rel="noopener noreferrer"
            css={{ fontSize: '100%' }}
          >
            {cartContinueShopping}
          </Link>
        </Paragraph>
      </TermsAndConditions>
    </Wrapper>
  );
};

export default Form;
