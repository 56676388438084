import React, {
  useState, useEffect, useMemo, useCallback, useRef,
} from 'react';
import { rem } from 'polished';
import { motion, AnimateSharedLayout } from 'framer-motion';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { createNamedStyled } from '../../../stitches.config';
import { useTheme } from '../../../theme';

import { useLocation } from '../../../context/Location';
import { useStore, useStoreCurrency } from '../../../context/Store';
import { useAnalytics } from '../../../context/Analytics';
import { useProduct } from '../../../context/Product';
import { useCart } from '../../../context/Cart';

import { useDictionary } from '../../../context/Language';

import { Title, Paragraph } from '../../../components/Elements/Text';
import Button from '../../../components/Elements/Button';
import PriceWithCurrency, {
  getPriceWithVat,
  getPriceWithCurrency,
} from '../../../helpers/PriceWithCurrency';

import Sections from '../../../components/Legacy/Sections';

import Gallery from '../../../components/Patterns/Gallery';
import RelatedProducts from './RelatedProducts';

import NotFound from '../../../components/Elements/NotFound';

import DropSelect from '../../../components/Patterns/DropSelect';
import ArPreview from '../../../components/Patterns/ArPreview';
import Input from '../../../components/Elements/Input';

import ScrollToTop from '../../../helpers/ScrollToTop';

import Types from '../../../modules/types';

const styled = createNamedStyled('Product');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '$siteWidth',
  margin: 'auto',
  gap: '$l',
});

const Main = styled.named('Main')('div', {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',

  padding: '$s $s $s',
  gap: '$m',
  '@desktop+': {
    padding: '$xs $m $m',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '$l',
  },
});

// eslint-disable-next-line no-unused-vars
const GalleryWrapper = styled.named('GalleryWrapper')('div', {
  position: 'relative',
  width: '100%',
});

const Content = styled.named('Content')('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: 'auto',
  padding: 0,
  gap: '$m',

  width: '100%',
  '@desktop+': { width: '80%' },
});

const Header = styled.named('Header')('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '$s',
});

const TitleWrapper = styled.named('TitleWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
});

const RatingWrapper = styled.named('RatingWrapper')('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$xs',
  alignItems: 'center',
  padding: 'calc($xs / 4)',

  '*': { whiteSpace: 'nowrap' },
});

const Rating = styled.named('Rating')('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

const RatingIcon = styled.named('RatingIcon')('i', {
  color: '$brand',
  fontSize: '$s',
});

const PriceWrapper = styled.named('PriceWrapper')('div', {
  display: 'flex',
  alignItems: 'baseline',
  gap: '$s',
});

const Row = styled.named('Row')('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$s',
  alignItems: 'center',
});

const Price = styled.named('Price')(Paragraph, {
  lineHeight: '100%',
  variants: {
    strikeThrough: {
      true: {
        textDecoration: 'line-through',
        opacity: 0.4,
      },
    },
  },
});

const OptionsWrapper = styled.named('OptionsWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xs',
});

const ColorsWrapper = styled.named('ColorsWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
});

const Colors = styled.named('Colors')('div', {
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: 12,
  marginBottom: 24, // TODO: check this
  gap: '$xs',
});

const Color = styled.named('Color')('div', {
  position: 'relative',
  width: 26,
  height: 26,
  borderRadius: '50%',
  cursor: 'pointer',
  margin: '0 5px',
  backgroundPosition: 'center',
  backgroundSize: 'cover',

  transition: 'opacity 300ms',
  '&:hover': { opacity: 0.8 },

  variants: {
    isActive: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0.5,
      },
    },
    faded: {
      true: {
        opacity: 0.4,
      },
    },
  },
});

const DropSelectWithValueLabel = ({
  value,
  options = [],
  ...props
}) => {
  const option = useMemo(
    () => options.find(
      ({ value: optionValue }) => optionValue === value,
    ),
    [options, value],
  );
  return (
    <DropSelect
      {...props}
      value={option?.label}
      options={options}
    />
  );
};

const Select = styled.named('Select')(motion.div, {
  position: 'absolute',
  top: -5,
  left: -5,
  right: -5,
  bottom: -5,
  borderRadius: '50%',
  border: '2px solid $accent',
});

const ProductOptionColors = ({
  value,
  onChange,
  label,
  choices,
  type,
  // eslint-disable-next-line no-unused-vars
  lang,
}) => {
  const options = choices;

  const option = useMemo(
    () => (
      options.find(testOption => testOption.value === value) || options[0]
    ),
    [options, value],
  );

  return (
    <AnimateSharedLayout>
      <ColorsWrapper>
        <Paragraph css={{ opacity: 0.8, fontSize: rem(12) }}>
          {label}
        </Paragraph>
        <Colors>
          {options.map((item) => (
            <Color
              key={item._id}
              isActive={item._id === option.value}
              onClick={() => onChange(item)}
              title={item.label}
              css={
                  type === 'COLOR'
                ? { backgroundColor: item.color }
                : type === 'IMAGE'
                ? { backgroundImage: `url("${item.image?.src}")` }
                : undefined
              }
              faded={item.faded}
            >
              {item.value === option.value && (
                <Select
                  key="color-select"
                  layoutId="color-select"
                  initial={false}
                  transition={{ type: 'spring', stiffness: 300, damping: 25 }}
                />
              )}
            </Color>
          ))}
        </Colors>
      </ColorsWrapper>
    </AnimateSharedLayout>
  );
};

const ProductSingle = ({
  store,
  data,
  language,
  variationSlug,
  getProductsUrl,
}) => {
  const {
    categories: storeCategories,
    imageSizing: storeImageSizing,
    paymentTabbySupported,
    systemCountry,
  } = store;
  const { currency: systemCurrency } = Types.getSystemCountry(systemCountry);
  const cart = useCart();
  const analytics = useAnalytics();
  const { search } = useLocation();
  const history = useHistory();

  const { addToCart, itemsMap } = cart;

  const {
    gallery,
    image,
    name,
    tagline,
    category,
    subcategory,
    relatedProducts,
    sections,
    slug: productSlug,
    imageSizing: productImageSizing,
  } = data;

  // eslint-disable-next-line no-unused-vars
  const [currencyObject, setCurrency] = useStoreCurrency();

  const {
    generalAddToCart,
    generalItemLeft,
    generalItemsLeft,
    generalItemOutOfStock,
    // generalSelectVariation,
    currency: translatedCurrency,
    cartRealChargeDisclaimer: labelRealChargeDisclaimer,

    reviewSingular,
    reviewPlural,
  } = useDictionary();

  const currency = translatedCurrency[systemCurrency];

  const variations = useMemo(
    () => data.variations.map(variation => ({
      ...variation,
      // disabled: !variation.inStock,
      disabled: false,
      faded: !variation.inStock,
    })),
    [data.variations],
  );

  const variation = useMemo(
    () => variations.find(
      test => test.slug === variationSlug
    ) || variations[0],
    [variationSlug, variations],
  );

  const optionValuesDefaults = useMemo(
    () => data.options.reduce(
      (agr, option) => {
        agr[option._id] = (
            ['CHOICE', 'COLOR', 'IMAGE'].includes(option.type)
          ? option.choices?.[0]?._id
          : ['TEXT'].includes(option.type)
          ? ''
          : undefined
        );
        return agr;
      },
      {},
    ),
    [data.options],
  );

  const [optionValues, setOptionValues] = useState(optionValuesDefaults);
  const setOptionValue = (optionId, value) => {
    setOptionValues({
      ...optionValues,
      [optionId]: value,
    });
  };

  useEffect(
    () => {
      if (
        variationSlug !== variation.slug
        || variation.disabled
      ) {
        history.replace(getProductsUrl(
          data.slug,
          variations.find(variation => variation.stock > 0)?.slug  || (variations.find(test => !test.disabled) || variations[0])?.slug,
          search,
        ));
      }
    },
    [
      variationSlug,
      variation,
      variations,
      search,
      data.slug,
      getProductsUrl,
      history,
    ],
  );

  const imageSizing = useMemo(
    () => productImageSizing || storeImageSizing,
    [storeImageSizing, productImageSizing],
  );

  const images = useMemo(
    () => {
      const imagesArr =  [
        ...(gallery.length === 0)
        // ? [`${(variation.image || image).src}?main=true`] : [],
        ? [variation.image || image] : [],
        ...(
          (gallery || [])
          .filter(({ skus }) => (
            !skus
            || !skus.length
            || skus.includes(variation.storeSku)
          ))
          .map(({ image: fullImage }) => fullImage)
        ),
      ];
      // get the default color image if no size color 
      let repalcementImages = [];
      if( imagesArr.length < 1 ) {
        const str = variation.storeSku.split("_");

        repalcementImages =  gallery.map(item => {
          let founded = item.skus.find(skk => {
            if(skk.split('_').at(-1) === str.at(-1) || skk.split('_').at(-2) === str.at(-2) || skk.split('_').at(-2) === str.at(-3)) {
              return item;
            } 
          });
          console.log({founded})
          return founded && item;
        }).filter(cleanedItems => cleanedItems !== undefined);
      }

      return imagesArr.length > 0 ? imagesArr : repalcementImages;
    },
    [variation, image, gallery],
  );
  const relatedProductsMap = useMemo(
    () => relatedProducts.map(relatedProduct => ({
      ...relatedProduct,
      images: [
        (relatedProduct.variations[0].image || relatedProduct.image),
        ...(relatedProduct.gallery || []),
      ].map((img) => img.src),
      price: relatedProduct.priceLowest,
      priceFormatted: `${
        currency
      } ${
        Types.decimalize(relatedProduct.priceLowest)
      }`,
    })),
    [relatedProducts, currency],
  );
  const categoryPopulated = useMemo(
    () => storeCategories.find(cat => (cat._id === category)),
    [storeCategories, category],
  );
  const subcategoryPopulated = useMemo(
    () => categoryPopulated?.subcategories?.find?.(
      subcat => (subcat._id === subcategory)
    ),
    [categoryPopulated, subcategory],
  );

  const { custom: { lang } } = useTheme();

  useEffect(
    () => {
      if (data && variation && categoryPopulated) {
        analytics.trigger.PRODUCT_OPEN({
          product: {
            ...data,
            category: categoryPopulated || data.category,
          },
          variation,
          currency: systemCurrency,
        });
      }
    },
    [data, variation, categoryPopulated, analytics, systemCurrency],
  );

  const addSkuToCart = useCallback(
    () => addToCart(
      variation.sku,
      variation.price,
      {
        name,
        storeSku: variation.storeSku,
        variation: variation.name,
        tagline,
        image: (variation.image || image)?.src,
        options: optionValues,
        categoryName: categoryPopulated.name || 'Product',
        productSlug,
        variationSlug: variation.slug,
      },
    ),
    [
      variation,
      categoryPopulated,
      optionValues,
      addToCart,
      image,
      tagline,
      productSlug,
      name,
    ],
  );

  const variationsVariantsHashMap = useMemo(
    () => variations.reduce(
      (agr, currentVariation) => {
        const variantsHash = data.variants.map(variant => (
          currentVariation.variants[variant._id]
        )).join('-');
        agr[variantsHash] = currentVariation;
        return agr;
      },
      {},
    ),
    [data.variants, variations],
  );

  const variationVariantsValuesHashArray = useMemo(
    () => (
      data.variants
      .map(variant => variation.variants[variant._id] || null)
    ),
    [data.variants, variation],
  );

  const variantsOptions = useMemo(
    () => data.variants.reduce(
      (agr, variant, variantIndex) => {
        const variantOption = {
          ...variant,
          choices: [],
        };
        variantOption.choices = variant.choices.reduce(
          (agrChoices, choice) => {
            const variantsHashArray = [
              ...variationVariantsValuesHashArray,
            ];
            variantsHashArray[variantIndex] = choice._id;
            const variantsHash = variantsHashArray.join(
              '-',
            );
            const choiceVariation = variationsVariantsHashMap[
              variantsHash
            ];
            // TODO Maybe skip all together if disabled
            if (choiceVariation) {
              // if (variation.slug === choiceVariation.slug) {
              //   variantOption.valueLabel = choice.label;
              // }
              agrChoices.push({
                ...choice,
                _id: choice._id,
                label: choice.label,
                color: choice.color,
                image: choice.image,
                value: choice._id,
                slug: choiceVariation.slug,
                // disabled: !!choiceVariation.disabled,
                disabled: false,
                faded: !!choiceVariation.faded,
              });
            }
            return agrChoices;
          },
          [],
        );
        if (variantOption.choices.length) {
          agr.push(variantOption);
        }
        return agr;
      },
      [],
    ),
    [
      data.variants,
      // variation?.slug,
      variationsVariantsHashMap,
      variationVariantsValuesHashArray,
    ],
  );

  const stockLeftToAdd = Math.max(
    0,
    variation.stock - (itemsMap[variation.sku]?.quantity || 0),
  );

  const META = useMemo(() => ({
    // title: `${name} (${variation.name})`,
    title: name,
    description: tagline || categoryPopulated?.name,
    url: window.location.href,
    image: (variation.image || image)?.src,
    brand: store.name,
    availability: variation.inStock ? 'in stock' : 'out of stock',
    condition: 'new',
    price: getPriceWithVat(
      variation.price,
      store,
      { code: systemCurrency },
      true,
    ),
    currency: systemCurrency,
    sku: variation.storeSku,
    category: categoryPopulated?.name,
  }), [
    name,
    // variation.name,
    tagline,
    variation.inStock,
    variation.price,
    store,
    systemCurrency,
    variation.storeSku,
    categoryPopulated?.name,
    image,
    variation.image,
  ]);
  console.log(META.price);
  
  const tabbyCurrencyObject = (
    // TODO Tabby merchantCode (new only)
    store.storeCurrenciesMap?.[Types.COUNTRIES_MAP[
      cart.defaultSubmitFields?.country
    ]?.currency] || store.storeCurrencies[0]
  );
  const { code: tabbyCurrency } = (
    tabbyCurrencyObject || {}
  );
  const tabbyPrice = getPriceWithVat(
    variation.price,
    store,
    tabbyCurrencyObject,
    true,
  );

  useEffect(
    () => {
      if (paymentTabbySupported) {
        // eslint-disable-next-line no-undef, no-unused-vars
        const tabbyPromo = new TabbyPromo({
          installmentsCount: 4,
          lang: language?._id === 'ar' ? 'ar' : 'en',
          selector: '#tabby-promo-product',
          // TODO Tabby merchantCode (new only)
          publicKey: (
            process.env.REACT_APP_TABBY_PUBLIC_KEY_THE_EXAMPLE_SHOP
          ),
          price: tabbyPrice,
          currency: tabbyCurrency,
          source: 'product',
        });
      }
    },
    [
      variation?._id,
      tabbyPrice,
      tabbyCurrency,
      paymentTabbySupported,
      language?._id,
      // TODO Tabby merchantCode
      store.storeId,
    ],
  );

  const {metaTitle, metaDescription, metaKeywordsString } = data;
  
  return (
    <Wrapper>
      <Helmet>
        <title>
          {`${store.name} | ${name} (${variation.name})`}
        </title>
        <meta name="title" content={metaTitle || META.title} />
        <meta name="description" content={metaDescription || META.description} />
       {metaKeywordsString && <meta name="keywords" content={metaKeywordsString} />} 
       
       
        <meta property="og:type" content="product" />

        <meta name="og:title" content={metaTitle || META.title} />
        <meta name="og:description" content={metaDescription || META.description} />

        <meta property="og:url" content={META.url} />
        <meta property="og:image" content={META.image} />
        <meta property="product:brand" content={META.brand} />
        <meta property="product:availability" content={META.availability} />
        <meta property="product:condition" content={META.condition} />
        <meta property="product:price:amount" content={META.price} />
        <meta property="product:price:currency" content={META.currency} />
        <meta property="product:retailer_item_id" content={META.sku} />
        <meta property="product:item_group_id" content={META.category} />
      </Helmet>
      <ScrollToTop />
      <Main>
        <Gallery
          items={images}
          imageSizing={imageSizing.toLowerCase()}
          aspectRatio={store.styleImageAspectRatio}
          roundness={store.styleRoundness}
          wheelGestures
          loop
        />
        <Content>
          <Header>
            <TitleWrapper>
              <Title bold>{name}</Title>
              <Paragraph css={{ opacity: 0.8 }}>
                {`${categoryPopulated ? categoryPopulated?.name : ''}${
                  subcategoryPopulated
                  ? ` / ${subcategoryPopulated?.name}`
                  : ''
                }`}
              </Paragraph>
            </TitleWrapper>
            {(data?.reviewCount > 0 && store.reviewShowRatingOnProductPage) && (
              <RatingWrapper>
                <Rating>
                  <Paragraph>
                    {data?.rating.toFixed(1)}
                  </Paragraph>
                  <Paragraph
                    css={{
                      marginTop: 'calc($xs * -0.5)',
                      fontSize: '75%',
                      opacity: 0.8,
                    }}
                  >
                    {data?.reviewCount}
                    {' '}
                    {data?.reviewCount === 1
                      ? reviewSingular || 'Review'
                      : reviewPlural || 'Reviews'}
                  </Paragraph>
                </Rating>
                <RatingIcon
                  className="ri-star-line"
                />
              </RatingWrapper>
            )}
          </Header>
          <PriceWrapper>
            <Price css={{ fontSize: '$s', color: '$title' }}>
              <PriceWithCurrency
                value={META.price }
                product
              />
            </Price>
            {
                variation.priceBeforeSale > 0
              ? (
                variation.priceBeforeSale > META.price  &&   (<Price strikeThrough>
                    <PriceWithCurrency
                      value={variation.priceBeforeSale}
                      product
                    />
                  </Price>)
                )
              : null
            }
          </PriceWrapper>
          {
            currencyObject.code !== systemCurrency
            ? (
              <Paragraph
                css={{ fontSize: '80%', opacity: 0.5, marginTop: '-$m' }}
              >
                {'* '}
                {labelRealChargeDisclaimer}
                {' '}
                {`${getPriceWithCurrency(
                  variation.price,
                  store,
                  { code: systemCurrency },
                  true
                )}`}
              </Paragraph>
            )
            : null
          }
          <OptionsWrapper>
            {/*
              Old Variations Picker
            */}
            {/* <DropSelect
              fullWidth
              label={generalSelectVariation}
              options={variations.map(option => ({
                ...option,
                label: option.name,
                value: option.slug,
              }))}
              value={variation.name}
              valueStyle="label"
              onChange={event => history.push(getProductsUrl(
                data.slug,
                event.value,
                search,
              ))}
            /> */}
            {/*
              Nev Variants Picker
            */}
            {variantsOptions.map((variantOption) => (
                variantOption.type === 'CHOICE'
              ? (
                  <DropSelectWithValueLabel
                    key={variantOption._id}
                    fullWidth
                    label={variantOption.label}
                    options={variantOption.choices}
                    value={variation.variants[variantOption._id]}
                    valueStyle="label"
                    onChange={event => (
                      !event.disabled && history.push(getProductsUrl(
                        data.slug,
                        event.slug,
                        search,
                      ))
                    )}
                  />
                )
              : variantOption.type === 'COLOR' || variantOption.type === 'IMAGE'
              ? (
                  <ProductOptionColors
                    key={variantOption._id}
                    lang={lang}
                    label={variantOption.label}
                    type={variantOption.type}
                    choices={variantOption.choices}
                    value={variation.variants[variantOption._id]}
                    onChange={event => (
                      !event.disabled && history.push(getProductsUrl(
                        data.slug,
                        event.slug,
                        search,
                      ))
                    )}
                  />
                )
              : null
            ))}
            {data?.options?.map((option) => (
              (option.type === 'CHOICE' && option.choices?.length > 0)
              ? (
                  <DropSelectWithValueLabel
                    key={option._id}
                    fullWidth
                    label={option.label}
                    options={option.choices.map(choice => ({
                      _id: choice._id,
                      label: choice.label,
                      value: choice._id,
                    }))}
                    value={optionValues[option._id]}
                    valueStyle="label"
                    onChange={event => setOptionValue(
                      option._id,
                      event.value,
                    )}
                  />
                )
              : (
                  (option.type === 'COLOR' || option.type === 'IMAGE')
                  && option.choices?.length > 0
                )
              ? (
                  <ProductOptionColors
                    key={option._id}
                    lang={lang}
                    label={option.label}
                    type={option.type}
                    choices={option.choices.map(choice => ({
                      _id: choice._id,
                      label: choice._label,
                      value: choice._id,
                      color: choice.color,
                      image: choice.image,
                    }))}
                    value={optionValues[option._id]}
                    onChange={event => setOptionValue(
                      option._id,
                      event.value,
                    )}
                  />
                )
              : option.type === 'TEXT'
              ? (
                  <Input
                    key={option._id}
                    label={option.label}
                    value={optionValues[option._id]}
                    onChange={event => setOptionValue(
                      option._id,
                      event,
                    )}
                  />
                )
              : null
            ))}
          </OptionsWrapper>
          <Row
            css={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '$xs',
            }}
          >
            <Row>
              <Button
                disabled={stockLeftToAdd <= 0 || !variation.inStock}
                onClick={() => addSkuToCart(variation.sku)}
                aria-label={generalAddToCart}
                notification={(
                  itemsMap[variation.sku]
                  ? itemsMap[variation.sku].quantity
                  : false
                )}
              >
                {generalAddToCart}
              </Button>
              <ArPreview
                models={data?.augmentedRealityModels?.find(
                  item => item.skus.includes(variation.storeSku))
                  || data?.augmentedRealityModels
                    .find(item => item.skus.length === 0)}
              />
            </Row>
            {
              variation.stock <= 0
              ? (
                  <Paragraph style={{ fontSize: '90%', opacity: 0.6 }}>
                    {generalItemOutOfStock}
                  </Paragraph>
                )
              : (
                  store.showItemsLeftInStockThreshold === -1
                  || (variation.stock > store.showItemsLeftInStockThreshold)
                )
              ? null
              : (
                  <Paragraph style={{ fontSize: '90%', opacity: 0.6 }}>
                    {variation.stock}
                    &nbsp;
                    {
                      variation.stock === 1
                      ? generalItemLeft
                      : generalItemsLeft
                    }
                  </Paragraph>
                )
            }
          </Row>
          {
            paymentTabbySupported
            ? (
                <Paragraph
                  // key={variation?._id || 'undefined'}
                  id="tabby-promo-product"
                />
              )
            : null
          }

          <Paragraph
            $$name="ProductDescription"
            css={{ whiteSpace: 'pre-wrap' }}
          >
            {tagline}
          </Paragraph>
          {!!variation.description && (
            <Paragraph
              $$name="ProductVariationDescription"
              css={{ whiteSpace: 'pre-wrap' }}
            >
              {variation.description}
            </Paragraph>
          )}
        </Content>
      </Main>
      <Sections sections={sections} />
      {!!relatedProductsMap.length && (
        <RelatedProducts products={relatedProductsMap} />
      )}
    </Wrapper>
  );
};

export default function ProductSingleBound(props) {
  const { generalProductNotFound } = useDictionary();

  const {
    data: store,
    // loading: loadingStore,
    getProductsUrl,
    language,
  } = useStore();

  const {
    data: product,
    loading: loadingProduct,
    variationSlug,
  } = useProduct();

  if (!store || loadingProduct) {
    return null;
  }

  if (store && product && !loadingProduct) {
    return (
      <ProductSingle
        {...props}
        language={language}
        loading={false}
        store={store}
        getProductsUrl={getProductsUrl}
        data={product}
        variationSlug={variationSlug}
      />
    );
  }

  return (
    <NotFound message={generalProductNotFound} />
  );
}
