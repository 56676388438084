/* global ApplePaySession */

import React, { useState, useEffect, useCallback } from 'react';

import { createNamedStyled } from '../../stitches.config';

import { useApi } from '../../context/Api';

import Types from '../../modules/types';
import { useCart } from '../../context/Cart';

const styled = createNamedStyled('PaymentRequestButton');
import { useTheme } from '../../theme';


const ApplePay = styled.named('ApplePay')('div', {
  display: 'inline-block',
  '-webkit-appearance': '-apple-pay-button',
  '-apple-pay-button-type': 'checkout', /* Use any supported button type. */
  '-apple-pay-button-style': 'white-outline',
  borderRadius: '$m',
  borderColor: '$shade50',
  opacity: 0.7,
  width: '100%',
  height: '74px',
  cursor: 'pointer',
});

const PaymobApplePayButton = styled.named('PaymobApplePayButton')('div', {
  transition: 'opacity 300ms',
  width: '100%',
  h2: {
    display: 'none',
  },
});

export default function PaymobPaymentRequestButton({ order, store }) {
  const [isSupported, setIsSupported] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const { charged } = order.cost;
  const cart = useCart();



  const {
    language: themeLanguage,
    radii,
    colors,
  } = useTheme();

  const {
    country,
    currency,
    checkoutComChannel,
    getCheckoutComVersionBasedOnStoreId,
  } = Types.getSystemCountry(store.systemCountry);
  const api = useApi();
  useEffect(
    () => {
      if (typeof ApplePaySession !== 'undefined') {
        try {
          const result = ApplePaySession.canMakePayments();
          setIsSupported(!!result);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('apple error:', error);
          setIsSupported(false);
        }
      }
    },
    [],
  );

  useEffect(() => {
    let timeout = null;
    const callback = () => {
      if (!window.Pixel) {
        // eslint-disable-next-line no-console
        console.log('No paymob, delaying:', window.Pixel);
        timeout = setTimeout(
          callback,
          500,
        );
        return;
      }
        const options = {
          publicKey: order.payment.paymobCardData.paymobPublicKey,
          clientSecret: order.payment.paymobCardData.paymobClientSecret,
          paymentMethods: ['card'],
          disablePay: true,
          showPaymobLogo: false,
          elementId: 'paymob-card-element',
          hideCardHolderName: true,
          customStyle: {
            Font_Weight_Label: store?.styleFontLabel?.variantNormal,
            Font_Weight_Input_Fields: store?.styleFontLabel?.variantNormal,
            Color_Border_Input_Fields: store?.brandColor,
            Color_Input_Fields: colors?.backgroundCard,
            Text_Color_For_Input_Fields: colors?.text,
            Color_For_Text_Placeholder: colors?.text,
            Radius_Border: radii.s.replace('px', ''),
          },
          cardValidationChanged : (isValid) => {
            console.log( "Is Valid ?", isValid);
            setFormStateError(isValid);
            isValid === true && setFormStateError(true)
          }
        };
        if (order.payment?.submethod  && order.payment?.submethod === 'APPLE_PAY') {
          options.paymentMethods = ['apple-pay'];
          options.elementId = 'paymob-apple-pay-element';
          // options.afterPaymentComplete = async () => {
          //   setDisabled(true);
          //   // setIsSubmittingLocal(false);
          //   // console.log("testing payment: afterPaymentComplete")
          // };
          options.onPaymentCancel = async () => {
            setDisabled(false);
            console.log("testing payment: onPaymentCancel")
            try {
              await cart.cancelPreviousOrder();
            } catch (error) {
              // console.log()
            }
        
            cart.reEvaluate();
            // ammar: disbled here & used in top 
            // setIsSubmittingLocal(false);
          };
          options.beforePaymentComplete = async () => {
            setDisabled(true);
            // setIsSubmittingLocal(true);
            // const result = await new Promise((resolve) => {
            //   handleSubmit(
            //     async (values) => {
            //       const extraProps = {};
            //       const submitValues = (
            //         fieldList.reduce((agr, { key, name }) => {
            //           agr[key] = values[name];
            //           return agr;
            //         }, {})
            //       );
            //       await cart.submit(submitValues, extraProps);
            //       if (cart.error) {
            //         // eslint-disable-next-line no-console
            //         console.log('🚨 Error:', cart.error);
            //         resolve(false);
            //       }
            //       resolve(true);
            //     },
            //     (submitErrors) => {
            //       const [name] = Object.keys(submitErrors);
            //       if (name) {
            //         const selector = `[name="${name}"]`;
            //         setIsSubmittingLocal(false);
            //         setIsCartSubmitting(false);
            //         const node = document.querySelector(selector);
            //         if (node?.scrollIntoView) {
            //           node.scrollIntoView({
            //             block: 'center',
            //             inline: 'nearest',
            //           });
            //         }
            //       }
            //       resolve(false);
            //     }
            //   )();
            // });
            // return result;
          };
        }
        // console.log(options);
        // eslint-disable-next-line no-new, no-undef
        new Pixel(options);
    };
    callback();
    return () => {
      clearTimeout(timeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // isInputInFocus,
    // cart.paymob.paymobClientSecret,
    // cart.submit,

    // cart,
    // fieldList,
    // handleSubmit,
    // colors.text,
    // colors.borderLight,
    // radii.s,
  ]);

  // const onClick = useCallback(
  //   async () => {
  //     try {
  //       // const r = new PaymentRequest([], {}, {});
  //       const paymentRequest = new PaymentRequest(
  //         [
  //           {
  //             supportedMethods: 'https://apple.com/apple-pay',
  //             data: {
  //               version: 3,
  //               merchantIdentifier: process.env[(
  //                 `REACT_APP_APPLE_PAY_MERCHANT_ID_${
  //                   checkoutComChannel.toUpperCase()
  //                 }`
  //               )],
  //               merchantCapabilities: [
  //                 'supports3DS',
  //               ],
  //               supportedNetworks: [
  //                 'amex',
  //                 'discover',
  //                 'masterCard',
  //                 'visa',
  //               ],
  //               countryCode: country.toUpperCase(),
  //             },
  //           },
  //         ],
  //         {
  //           total: {
  //             label: 'Total',
  //             amount: {
  //               currency,
  //               value: charged.toFixed(2),
  //             },
  //           },
  //           displayItems: [],
  //           // displayItems: order.items.map(({ name, quantity, price }) => ({
  //           //   label: `${quantity} x ${name}`,
  //           //   amount: {
  //           //     currency,
  //           //     value: (price * quantity).toFixed(2),
  //           //   },
  //           // })),
  //           shippingOptions: [],
  //         },
  //         {
  //           // requestShipping: true,
  //           // shippingType: 'shipping',
  //         },
  //       );
  //       paymentRequest.onmerchantvalidation = (event) => {
  //         const validationRequest = api.post('apple-pay/validate-session', {
  //           validationURL: event.validationURL,
  //           domainName: window.location.hostname,
  //           checkoutComVersion: getCheckoutComVersionBasedOnStoreId(store._id),
  //           checkoutComChannel,
  //         });

  //         event.complete(validationRequest);
  //       };
  //       paymentRequest.onshippingaddresschange = (event) => {
  //         event.updateWith({});
  //       };
  //       const paymentResponse = await paymentRequest.show();
  //       // const submitResponse = await api.post('orders/submit', {
  //       //   paymentCardProviderData: {
  //       //     applePayData: paymentResponse.details.token.paymentData,
  //       //   },
  //       // }, {
  //       //   checkout: order._id,
  //       // });
  //       // if (/^INVALID/.test(submitResponse.status || '')) {
  //       //   await paymentResponse.complete('fail');
  //       //   throw new Error(order.message || 'Submit Failed');
  //       // }
  //       await paymentResponse.complete('success');
  //       if (submitResponse?.payment?.paytabsPaymentPageUrl) {
  //         window.location.href = order.payment.paytabsPaymentPageUrl;
  //       } else if (submitResponse?.payment?.checkoutRedirectUrl) {
  //         window.location.href = order.payment.checkoutRedirectUrl;
  //       } else {
  //         window.location.reload();
  //       }
  //     } catch (error) {
  //       // eslint-disable-next-line no-console
  //       console.log(error);
  //       window.location.reload();
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [order, store],
  // );
  return (
        // <ApplePay
        //   data-purpose="apple-pay"
        //   onClick={onClick}
        // />
      <div style={{width: '100%'}}>
      <PaymobApplePayButton
        css={{
          ...(isDisabled)
          && {
            opacity: 0.7,
            animation: `1000ms 300ms infinite alternate`,
            pointerEvents: 'none',
          },
        }}
        id="paymob-apple-pay-element"
      />
      </div>
  );
}
